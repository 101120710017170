import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Foundation = () => {
  const [showStatutes, setShowStatutes] = useState(false);
  const { t } = useTranslation();

  return (
    <section id="foundation" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('foundation.title')}</h2>
        <div className="bg-[#3E5641] p-8 rounded-lg shadow-lg">
          <p className="text-lg mb-4">{t('foundation.description')}</p>
          <a 
            href="https://www.heimlandr.org" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block bg-[#8B5E3C] text-[#E6D3B1] py-2 px-6 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 mb-6"
          >
            {t('foundation.learnMore')}
          </a>
          <p className="text-lg mb-4">{t('foundation.statutes.description')}</p>
          <button 
            onClick={() => setShowStatutes(true)} 
            className="text-[#F4A261] hover:text-[#E6D3B1] transition duration-300"
          >
            {t('foundation.statutes.viewStatutes')}
          </button>
        </div>
      </div>

      {showStatutes && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#2E4D3A] p-8 rounded-lg shadow-lg max-w-2xl max-h-[80vh] overflow-y-auto relative">
            <button 
              onClick={() => setShowStatutes(false)} 
              className="absolute top-4 right-4 text-[#E6D3B1] hover:text-[#F4A261]"
            >
              <X size={24} />
            </button>
            <h3 className="text-2xl font-bold mb-4">{t('foundation.statutes.title')}</h3>
            <p className="mb-4">{t('foundation.statutes.purpose')}</p>
            <h4 className="text-xl font-bold mb-2">{t('foundation.statutes.purposeTitle')}</h4>
            <ul className="list-disc pl-6 space-y-2">
              {Array.from({ length: 12 }).map((_, index) => (
                <li key={index}>{t(`foundation.statutes.purposes.${index + 1}`)}</li>
              ))}
            </ul>
            <p className="mt-4">{t('foundation.statutes.independent')}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Foundation;
import React from 'react';
import { Users, Bot } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t } = useTranslation();
  const teamMembers = [
    {
      name: "Fredrik Brunnberg",
      key: "fredrik"
    },
    {
      name: "Moa Grundemark",
      key: "moa"
    },
    {
      name: "Fredrik Ejemo",
      key: "fredrikE"
    },
    {
      name: "Julia Carroll",
      key: "julia"
    }
  ];

  return (
    <section id="team" className="py-12 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">{t('team.title')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          {teamMembers.map((member, index) => (
            <div key={index} className="bg-[#3E5641] p-4 rounded-lg shadow-lg">
              <Users className="w-8 h-8 mb-2 text-[#F4A261]" />
              <h3 className="text-lg font-bold mb-1">{member.name}</h3>
              <h4 className="text-sm font-semibold mb-2 text-[#F4A261]">{t(`team.members.${member.key}.title`)}</h4>
              <p className="text-xs">{t(`team.members.${member.key}.description`)}</p>
            </div>
          ))}
        </div>

        {/* AI Employee Section */}
        <div className="mt-16">
          <h2 className="text-3xl font-bold mb-8 text-center">{t('team.ai.title')}</h2>
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <div className="flex items-center mb-4">
                  <Bot className="w-12 h-12 text-[#F4A261] mr-4" />
                  <h3 className="text-2xl font-bold">HEIMAR</h3>
                </div>
                <p className="text-lg mb-6">{t('team.ai.description')}</p>
                
                <h4 className="text-xl font-bold mb-4">{t('team.ai.tasks.title')}</h4>
                <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-8">
                  {t('team.ai.tasks.list', { returnObjects: true }).map((task, index) => (
                    <li key={index} className="flex items-center">
                      <span className="w-2 h-2 bg-[#F4A261] rounded-full mr-2"></span>
                      {task}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="bg-[#2C3A4A] p-6 rounded-lg">
                <h4 className="text-xl font-bold mb-6">{t('team.ai.pricing.title')}</h4>
                
                <div className="mb-6">
                  <h5 className="font-semibold mb-2">{t('team.ai.pricing.implementation')}</h5>
                  <p className="text-2xl font-bold text-[#F4A261]">25,000 - 40,000 SEK</p>
                </div>

                <div className="mb-6">
                  <h5 className="font-semibold mb-2">{t('team.ai.pricing.monthly')}</h5>
                  <p className="text-2xl font-bold text-[#F4A261]">5,000 - 10,000 SEK</p>
                </div>

                <p className="text-sm italic mb-6">{t('team.ai.pricing.note')}</p>

                <div className="bg-[#F4A261] text-[#2C3A4A] p-3 rounded-lg mb-6">
                  <p className="font-bold text-center">{t('team.ai.recommended')}</p>
                </div>

                <p className="mb-6">{t('team.ai.transform')}</p>

                <a 
                  href="https://www.heimlandr.io" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="block w-full bg-[#8B5E3C] text-[#E6D3B1] py-3 px-6 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-center"
                >
                  {t('team.ai.cta')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
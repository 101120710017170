import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('contact.title')}</h2>
        <div className="max-w-2xl mx-auto">
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block mb-2">{t('contact.name')}</label>
              <input type="text" id="name" name="name" className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]" required />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2">{t('contact.email')}</label>
              <input type="email" id="email" name="email" className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]" required />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2">{t('contact.message')}</label>
              <textarea id="message" name="message" rows={4} className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]" required></textarea>
            </div>
            <button type="submit" className="bg-[#8B5E3C] text-[#E6D3B1] py-2 px-6 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300">
              {t('contact.send')}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
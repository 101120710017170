import React from 'react';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className="hero-section min-h-screen relative overflow-hidden">
      <div className="hero-overlay absolute inset-0"></div>
      <div className="sun-flare"></div>
      <div className="absolute inset-0 flex items-center justify-center px-4">
        <div className="text-center max-w-7xl mx-auto">
          <h1 className="text-4xl xs:text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-['Bungee_Hairline'] mb-4 sm:mb-8 tracking-normal animate-fade-in leading-tight whitespace-nowrap">
            <span className="smokey-text">HEIMLANDR</span><span className="flicker-text">.COM</span>
          </h1>
          <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6 px-4">
            <a 
              href="#fundamentals" 
              className="w-full sm:w-auto bg-[#8B5E3C] text-[#E6D3B1] py-2 sm:py-3 px-4 sm:px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-sm sm:text-base md:text-lg btn-glow whitespace-nowrap"
            >
              {t('hero.learnMore')}
            </a>
            <a 
              href="#projects" 
              className="w-full sm:w-auto bg-[#8B5E3C] text-[#E6D3B1] py-2 sm:py-3 px-4 sm:px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-sm sm:text-base md:text-lg btn-glow whitespace-nowrap"
            >
              {t('hero.ourProjects')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
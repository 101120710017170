import React from 'react';
import { Leaf, Zap, Shield } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Fundamentals = () => {
  const { t } = useTranslation();

  return (
    <section id="fundamentals" className="py-20 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('fundamentals.title')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <Leaf className="w-12 h-12 mb-4 text-[#F4A261]" />
            <h3 className="text-2xl font-bold mb-2">{t('fundamentals.sustainability.title')}</h3>
            <p>{t('fundamentals.sustainability.description')}</p>
          </div>
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <Zap className="w-12 h-12 mb-4 text-[#F4A261]" />
            <h3 className="text-2xl font-bold mb-2">{t('fundamentals.innovation.title')}</h3>
            <p>{t('fundamentals.innovation.description')}</p>
          </div>
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <Shield className="w-12 h-12 mb-4 text-[#F4A261]" />
            <h3 className="text-2xl font-bold mb-2">{t('fundamentals.community.title')}</h3>
            <p>{t('fundamentals.community.description')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Fundamentals;
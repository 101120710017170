import React from 'react';
import { Home, Battery, Globe, Users, Sun, Compass, Database, Vote, DollarSign } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  const services = [
    { icon: Home, key: 'ecoVillageDevelopment' },
    { icon: Battery, key: 'sustainableHousing' },
    { icon: Globe, key: 'offGridLiving' },
    { icon: Users, key: 'communityBuilding' },
    { icon: Sun, key: 'renewableEnergy' },
    { icon: Compass, key: 'ecoTourism' },
    { icon: Database, key: 'tokenization' },
    { icon: Vote, key: 'governance' },
    { icon: DollarSign, key: 'defi' },
  ];

  return (
    <section id="services" className="py-20 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('services.title')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <article key={index} className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
              <service.icon className="w-12 h-12 mb-4 text-[#F4A261]" aria-hidden="true" />
              <h3 className="text-xl sm:text-2xl font-bold mb-2">{t(`services.${service.key}.title`)}</h3>
              <p className="text-sm sm:text-base">{t(`services.${service.key}.description`)}</p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
import React from 'react';
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const { t } = useTranslation();
  const partners = [
    { name: 'BIOBUILDS', url: 'https://www.biobuilds.com' },
    { name: 'HEIMLANDR.IO', url: 'https://www.heimlandr.io' },
    { name: 'AHLSELL', url: 'https://www.ahlsell.com' },
    { name: 'DSEG', url: 'https://www.dseg.se' },
    { name: 'HEIMLANDR.ORG', url: 'https://www.heimlandr.org' },
    { name: 'NOMADIC HOMES', url: 'https://nomadic-homes.com/' },
    { name: 'EKO BANKEN', url: 'https://www.ekobanken.se' },
    { name: 'SOKASTIFTELSEMEDEL.SE', url: 'https://www.sokastiftelsemedel.se' },
    { name: 'HEIMLANDR.SE', url: 'https://www.heimlandr.se' },
    { name: 'TOKN.SE', url: 'https://www.tokn.se' },
    { name: 'TSEB.SE', url: 'https://www.tseb.se' },
    { name: 'STIFTELSEGUIDEN.SE', url: 'https://www.stiftelseguiden.se' }
  ];

  return (
    <section id="partners" className="py-20 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('partners.title')}</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {partners.map((partner, index) => (
            <a 
              key={index} 
              href={partner.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="partner-link bg-[#3E5641] p-3 rounded-lg shadow-lg flex items-center justify-center hover:bg-[#4A6D50] transition duration-300 min-h-[60px]"
            >
              <span className="text-xs md:text-sm font-bold text-center break-words px-1">{partner.name}</span>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
import React, { useState } from 'react';
import ProjectForm from './ProjectForm';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const [showProjectForm, setShowProjectForm] = useState(false);
  const { t } = useTranslation();

  return (
    <section id="projects" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('projects.title')}</h2>
        
        {/* Featured Project */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold mb-6 flex items-center">
            <span className="bg-[#F4A261] w-2 h-8 mr-3 rounded-full"></span>
            {t('projects.featuredInitiative')}
          </h3>
          <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <div className="grid lg:grid-cols-2 gap-6">
              <img 
                src="https://images.unsplash.com/photo-1518780664697-55e3ad937233?auto=format&fit=crop&q=80&w=1200" 
                alt="TSEB.SE Project" 
                className="w-full h-[400px] object-cover rounded-lg"
              />
              <div className="flex flex-col justify-between">
                <div>
                  <h4 className="text-2xl font-bold mb-4">{t('projects.tseb.title')}</h4>
                  <p className="text-base mb-6">{t('projects.tseb.description')}</p>
                </div>
                <div className="flex flex-wrap gap-4">
                  <a 
                    href="https://www.tseb.se" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-flex items-center bg-[#8B5E3C] text-[#E6D3B1] py-3 px-6 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300"
                  >
                    {t('projects.tseb.visitButton')}
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </a>
                  <a 
                    href="https://www.heimlandr.io" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-flex items-center bg-[#3E5641] text-[#E6D3B1] py-3 px-6 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 border border-[#F4A261]"
                  >
                    {t('projects.tseb.aiSystem')}
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </a>
                </div>
              </div>
            </div>
          </article>
        </div>

        {/* Current Developments */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold mb-6 flex items-center">
            <span className="bg-[#F4A261] w-2 h-8 mr-3 rounded-full"></span>
            {t('projects.currentDevelopments')}
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg transform transition duration-300 hover:translate-y-[-4px]">
              <img src="https://i.imgur.com/ONW2Ckq.jpeg" alt="15 ECO Village Developments" className="w-full h-64 object-cover rounded-lg mb-4" />
              <h4 className="text-2xl font-bold mb-2">{t('projects.ecoVillages.title')}</h4>
              <p className="text-base">{t('projects.ecoVillages.description')}</p>
            </article>
            <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg transform transition duration-300 hover:translate-y-[-4px]">
              <img src="https://i.imgur.com/gkp6lPb.jpeg" alt="Smart Home Community" className="w-full h-64 object-cover rounded-lg mb-4" />
              <h4 className="text-2xl font-bold mb-2">{t('projects.odeshog.title')}</h4>
              <p className="text-base">{t('projects.odeshog.description')}</p>
            </article>
          </div>
        </div>

        {/* Completed Projects */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold mb-6 flex items-center">
            <span className="bg-[#F4A261] w-2 h-8 mr-3 rounded-full"></span>
            {t('projects.completedProjects')}
          </h3>
          <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg transform transition duration-300 hover:translate-y-[-4px]">
            <div className="grid lg:grid-cols-2 gap-6">
              <img src="https://i.imgur.com/d2MhZ5j.png" alt="Eco Village" className="w-full h-64 object-cover rounded-lg" />
              <div>
                <h4 className="text-2xl font-bold mb-2">{t('projects.solfors.title')}</h4>
                <p className="text-base">{t('projects.solfors.description')}</p>
              </div>
            </div>
          </article>
        </div>

        {/* Submit Your Project */}
        <div className="text-center bg-[#3E5641] p-8 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">{t('projects.haveProject')}</h3>
          <p className="mb-6 max-w-2xl mx-auto">{t('projects.projectDescription')}</p>
          <button
            onClick={() => setShowProjectForm(true)}
            className="bg-[#8B5E3C] text-[#E6D3B1] py-3 px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-base sm:text-lg btn-glow"
          >
            {t('projects.submitProject')}
          </button>
        </div>
      </div>
      {showProjectForm && <ProjectForm onClose={() => setShowProjectForm(false)} />}
    </section>
  );
};

export default Projects;